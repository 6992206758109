import React, { Component } from 'react';
import { Table } from "react-bootstrap";

class RestauranstList extends Component {
    constructor() {
        super();
        this.state = {
            list: null,
        }
    }
    componentDidMount() {
        fetch("https://freetestapi.com/api/v1/users").then((response) => {
            response.json().then((result) => {

                this.setState({
                    list: result
                })
            })
        })


    }
    render() {

        return (
            <div>
                <h1>RestauranstList</h1>
                {

                    this.state.list ?
                        <div>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Age</th>
                                        <th>UserName</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.list.map((item, i) =>

                                            // <div>
                                            //     <span>{item.name}</span>
                                            //     <span>{item.email}</span>
                                            //     <span>{item.age}</span>
                                            //     <span>{item.username}</span>

                                            // </div>
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.age}</td>
                                                <td>{item.username}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                        :
                        <div>
                            Please wait ...
                        </div>

                }

            </div>
        );
    }
}

export default RestauranstList;