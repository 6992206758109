import React, { Component } from 'react';

class RestaurantDetail extends Component {
    render() {
        return (
            <div>
                <h1>Restauranst Detail</h1>
            </div>
        );
    }
}

export default RestaurantDetail;