import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes
} from 'react-router-dom';
import RestauranstList from "./components/RestauranstList";
import Details from "./components/RestaurantDetail";
import Search from "./components/RestaurantSearch";
import Create from "./components/RestaurantCreate";
import Update from "./components/RestaurantUpdate";
import { Navbar, Nav, Container,Table } from "react-bootstrap";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="#home">Users</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#home"><Link to="/">Home</Link></Nav.Link>
                <Nav.Link href="#link"><Link to="/list">List</Link></Nav.Link>
                <Nav.Link href="#link"><Link to="/search">Search</Link></Nav.Link>
                <Nav.Link href="#link"><Link to="/create">Create</Link></Nav.Link>
                <Nav.Link href="#link"><Link to="/update">Update</Link></Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        
        <Routes>
          <Route path='/list' element={<RestauranstList />} />
          <Route path='/details' element={<Details />} />
          <Route path='/search' element={<Search />} />
          <Route path='/create' element={<Create />} />
          <Route path='/update' element={<Update />} />
        </Routes>



      </Router>
    </div>
  );
}

export default App;
